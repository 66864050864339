import { AppProps, NextWebVitalsMetric } from 'next/app'
import getConfig from 'next/config'

import { SiteBuilderProvider } from '@/siteBuilder/renderer/context/SiteBuilderProvider'
import sitebuilderConfig from '@/sitebuilder.config'

// Components
import { GenericPageProps } from '@/siteBuilder/components/GenericPage'
import { KoodoGenericPageProps } from '../siteBuilder/components/KoodoGenericPage'
import { GlobalElements } from '@/siteBuilder/components/GlobalElements/GlobalElements'
import { Theme } from '@/siteBuilder/components/Theme/Theme'
import ErrorBoundary from '@/siteBuilder/components/errors/ErrorBoundary'

// Hooks

import useScrollOnLoad from '@/hooks/useScrollOnLoad'
import useAnalytics from '@/hooks/useAnalytics'
import { useWebP } from '@/hooks/useWebP'

// Global helpful css
import '../styles.css'

function SiteBuilderApp({ Component, pageProps, router }: AppProps<GenericPageProps | KoodoGenericPageProps>) {
  useAnalytics(!pageProps.excludes?.analytics && pageProps.analyticsScripts)

  const isWebpSupported = useWebP()
  // Remove the lang from the slug and remove any query parameters

  useScrollOnLoad()

  const brand = pageProps.brand

  let page: GenericPageProps['genericPageTemplate'] | KoodoGenericPageProps['koodoGenericPageTemplate']
  let geData = null
  if (brand === 'telus') {
    page = pageProps.genericPageTemplate
  } else if (brand === 'koodo') {
    page = pageProps.koodoGenericPageTemplate
    geData = pageProps.koodoGlobalElements
  }

  return (
    <GlobalElements brand={brand} disabled={pageProps.excludes?.ge} data={geData} locale={pageProps.locale}>
      <Theme brand={brand}>
        <SiteBuilderProvider
          currentPage={page}
          space={sitebuilderConfig.space}
          locale={pageProps.locale}
          isWebpSupported={isWebpSupported}
          brand={brand}
        >
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </SiteBuilderProvider>
      </Theme>
    </GlobalElements>
  )
}

export default SiteBuilderApp
